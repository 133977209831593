.share-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 90%;
    margin: auto;
    padding: 20px 0;
    font-family: "Arial", sans-serif;
    padding-bottom: 100px;
}

.share-details-item {
    background: linear-gradient(145deg, #ffffff, #f1f1f1);
    border-radius: 12px;
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.share-details-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.share-url {
    font-size: 20px;
    font-weight: 500;
    color: #2c3e50;
    font-style: italic;
    color: #0d7575;
    display: flex;
    align-items: center;
    font-weight: 600;
    word-wrap: break-word;
}

.share-actions {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.share-actions button {
    flex: 1;
    padding: 12px 15px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.copy-btn {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    flex: 1;
    padding: 12px 15px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.copy-btn:hover {
    background-color: rgba(0, 0, 0, 0.7);
    transform: scale(1.05);
}

.disable-btn {
    background-color: #d9534f;
    color: #fff;
}

.disable-btn:hover {
    background-color: #d9534f;
    transform: scale(1.05);
}

.regenerate-btn {
    color: #fff;
}

.regenerate-btn:hover {
    transform: scale(1.05);
}

.share-btn {
    background-color: #03A684;
    border: 1px solid #03A684;
    color: #fff;
}

.share-btn:hover {
    background-color: #03A684;
    transform: scale(1.05);
}

@media screen and (max-width:1200px) {
    .share-details-item {
        width: 100%;
        justify-content: space-between;
    }
}

@media screen and (max-width:900px) {
    .noText {
        display: none;
    }

    .copy-btn {
        padding: 5px 8px;
    }

    .share-details-item {
        flex-direction: column;
    }

    .share-url {
        align-items: baseline;
        padding-bottom: 20px;
        align-items: baseline;
        padding-bottom: 20px;
    }
}

@media (max-width: 600px) {
    .share-details-container {
        padding: 10px;
        padding-bottom: 100px;
    }

    .share-actions button {
        /* flex: unset; */
        width: 100%;
        padding: 5px 8px;
    }
}