.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 7px 35px;
    font-size: 24px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #FBF7F4;
    z-index: 20;
    height: max-content;
}

.nav-left {
    display: flex;
    justify-content: flex-start;
    width: 30%;
}

.nav-middle {
    display: flex;
    justify-content: center;
    width: 60%;
}

.logoSize {
    width: 70%;
}

.nav-right {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    align-items: inherit;
}

.connectDiv {
    display: flex;
    width: max-content;
    font-size: smaller;
}

.profileDiv {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    font-weight: 900;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: small;
    padding: 5px;
}

/* @media screen and (max-width:1200px){
    .logoSize {
        width: 70%;
    }
} */

@media (min-width: 600px) and (max-width: 1200px) {
    .brandIcon svg {
        min-height: 22px;
    }

    .navBrandText {
        display: block;
    }

    .profileDiv {
        width: 35px !important;
        height: 35px !important;
        min-width: 35px;
        min-height: 35px;
    }
}


@media screen and (max-width:600px) {
    .nav-middle {
        display: flex;
        justify-content: center;
        width: 60%;
        text-align: center;
        font-size: 1rem;
    }

    .profileDiv {
        width: 30px !important;
        height: 30px !important;
    }

    .logoSize {
        width: 60%;
    }

    .navbar {
        padding: 7px;
    }

    .nav-left {
        display: flex;
        justify-content: flex-start;
        width: 48%;
    }

    .connectDiv {
        font-size: x-small;
    }

    .nav-right {
        width: auto;
    }

}