.album_mainDiv {
    background-color: black;
    height: 100vh;
    overflow: auto;
    width: 100vw;
}

.playButtonClass {
    cursor: pointer;
}

.float {
    display: none;
}

.transition {
    display: none;
}

.instructions {
    color: white;
    width: -webkit-fill-available;
    padding: 0.7rem 1rem;
    font-size: 1.1rem;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
}

.navDiv {

    height: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 2rem 4rem 0 4rem;

}

.outerFlipDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -1rem 0.5rem 0 0.5rem;
    height: inherit;
}

.cpyAlbum {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.7);
    border: none;
    padding: 0.4rem 0.4rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
    gap: 10px;
    width: 12rem;
}

.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoWidth {
    width: 5rem;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
    margin-top: 100%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media screen and (max-height:600px) {
    .navDiv {
        display: none;
    }

    .logoWidth {
        width: 4rem;
    }

    .outerFlipDiv {
        margin: 0 2rem 0 0.4rem;
        padding-bottom: 2rem 0 2rem 0;
        /* width: 70%; */
        overflow: hidden;
    }

    .transition {
        display: none;
        position: absolute;
        right: 1%;
        bottom: 50%;
        color: white;
        border-radius: 50%;
        padding: 0 1.2%;
        display: none;
        cursor: pointer;
    }

    .transition.shareTrans {
        display: block;
        bottom: 78%;
        right: 0.8%;
    }

    .transition.playTrans {
        display: block;
        bottom: 90%;
        right: 1%;
    }

    .float {
        display: block;
        position: absolute;
        right: 1%;
        top: 3%;
        color: white;
        padding: 0 1%;
        overflow: hidden;
        cursor: pointer;
    }

    .float.open {
        right: 1%;
    }

    .cpyAlbum {
        margin: 15px auto;
    }

}